import { useContext, useRef } from "react";

import { DependencyContext } from "../../DependencyProvider";
import { GalleryItem } from "../../interfaces/gallery-item";
import { Rotation } from "../../interfaces/persist";
import Header from "../header/header";
import FooterComponent from "../footer/footer";
import ToolbarComponent from "../toolbar/toolbar";
import MainComponent from "../main/main";
import { ExifData } from "../../services/ExifManager";
import { useDispatch } from "react-redux";
import {
  addGalleryItems,
  setExifData,
  setPreviewUrl,
} from "../../reducers/itemsReducer";
import { AppDispatch } from "../../Store";

const PhotoLabelComponent: React.FC = () => {
  const imageRenderer = useContext(DependencyContext)!.imageRenderer;
  const loadAsyncQueue = useContext(DependencyContext)!.loadAsyncQueue;

  // dispatch
  const dispatch = useDispatch<AppDispatch>();

  // context
  const exifAsyncQueue = useContext(DependencyContext)!.exifAsyncQueue;
  const exifManager = useContext(DependencyContext)!.exifManager;
  const fileManager = useContext(DependencyContext)!.fileManager;
  const imageReader = useContext(DependencyContext)!.imageReader;
  const persistManager = useContext(DependencyContext)!.persistManager;

  // ref
  const nextId = useRef(1);

  const handleFileSelect = (files: FileList): void => {
    const newItems: GalleryItem[] = [];
    for (let i = 0; i < files.length; i++) {
      // get the id for this file
      const id = nextId.current++;

      // save the file
      fileManager.add(id, files[i]);

      // create the new item to add
      let galleryItem: GalleryItem = {
        edited: false,
        fileName: files[i].name,
        font: undefined,
        id,
        isExifLoaded: false,
        latitude: undefined,
        longitude: undefined,
        rotation: Rotation.None,
        saved: false,
        text: fileManager.getFileName(id),
      };

      // try and load the saved data
      const persistedGalleryItem = persistManager.get(files[i]);

      // if the data exists, use those values
      if (persistedGalleryItem) {
        galleryItem = {
          ...galleryItem,
          ...persistedGalleryItem,
        };
      }

      // load the preview
      loadAsyncQueue.enqueue(galleryItem.id, (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
          const file = fileManager.get(galleryItem.id)!;

          try {
            // read the file
            const image = await imageReader.read(file, false);

            // create the preview image
            const previewUrl = await imageRenderer.createPreview(image);

            // update the value
            dispatch(
              setPreviewUrl({
                id: galleryItem.id,
                previewUrl,
              })
            );

            // resolve the promise
            resolve();
          } catch (reason: any) {
            reject(reason);
          }
        });
      });

      // load the EXIF data
      exifAsyncQueue.enqueue(galleryItem.id, (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
          // get the file associated with this item
          const file = fileManager.get(galleryItem.id)!;

          exifManager
            .load(file)
            .then((value: ExifData) => {
              // update the item
              dispatch(
                setExifData({
                  id: galleryItem.id,
                  dateTaken: value.dateTaken,
                  description: value.description,
                  latitude: value.latitude,
                  longitude: value.longitude,
                })
              );

              // the task is done
              resolve();
            })
            .catch((reason: any) => {
              reject(reason);
            });
        });
      });

      // add it to the list of new items
      newItems.push(galleryItem);
    }

    // add the items to the list
    dispatch(addGalleryItems(newItems));
  };

  return (
    <>
      <Header />
      <ToolbarComponent onFileSelect={handleFileSelect} />
      <MainComponent />
      <FooterComponent />
    </>
  );
};

export default PhotoLabelComponent;
