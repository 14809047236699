import "./backcolour.css";

import { useDispatch } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { setGalleryItemBackColour } from "../../reducers/itemsReducer";
import { DependencyContext } from "../../DependencyProvider";

const BackColourComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(selectedItem === undefined);
  const [value, setValue] = useState(settings.defaultBackColour);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGalleryItemBackColour(event.target.value));
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setValue(selectedItem?.backColour ?? settings.defaultBackColour);
  }, [selectedItem, settings.defaultBackColour]);

  return (
    <input
      disabled={disabled}
      onChange={handleChange}
      className="backcolour"
      title="Background Colour"
      type="color"
      value={value}
    />
  );
};

export default BackColourComponent;
