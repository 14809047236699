export interface ICache<K, V> {
  get(key: K): V | undefined;
  push(key: K, value: V): V[];
}

export class Cache<K, V> implements ICache<K, V> {
  private items = new Array<{ key: K; value: V }>();

  get(key: K): V | undefined {
    return this.items.find((i) => i.key === key)?.value;
  }

  push(key: K, value: V): V[] {
    // if the key already exists, just update the value
    const item = this.items.find((i) => i.key === key);
    if (item) {
      item.value = value;

      return [];
    }

    // add the new item to the cache
    this.items.push({
      key,
      value,
    });

    // do we need to remove an item from the cache?
    if (this.items.length > 5) {
      return this.items.splice(0, 1).map((x) => x.value);
    }

    return [];
  }
}
