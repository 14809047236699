import "./canvas-height.scss";

import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppState } from "../../Store";
import { useSelector } from "react-redux";
import { DependencyContext } from "../../DependencyProvider";
import { setGalleryItemCanvasHeight } from "../../reducers/itemsReducer";
import { useAppDispatch } from "../../Hooks";

const CanvasHeightComponent: FC = () => {
  const dispatch = useAppDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );
  const height = useSelector(
    (state: AppState) => state.items.selectedItem?.canvasHeight
  );

  // state
  const [disabled, setDisabled] = useState(
    selectedItem === undefined || !selectedItem.useCanvas
  );
  const [localHeight, setLocalHeight] = useState<number>(
    height ?? settings.defaultCanvasHeight
  );

  // refs
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.target.value);

    // set the value to use in the control
    setLocalHeight(value);

    // cancel any pending update
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      dispatch(setGalleryItemCanvasHeight(value));
    });
  };

  useEffect(() => {
    const useCanvas = selectedItem?.useCanvas ?? settings.defaultUseCanvas;

    setDisabled(selectedItem === undefined || !useCanvas);
    setLocalHeight(selectedItem?.canvasHeight ?? settings.defaultCanvasHeight);
  }, [selectedItem, settings.defaultCanvasHeight, settings.defaultUseCanvas]);

  return (
    <div className="canvas-height">
      H
      <input
        disabled={disabled}
        min="1"
        onChange={handleChange}
        value={localHeight}
        type="number"
      />
    </div>
  );
};

export default CanvasHeightComponent;
