import EXIF from "exif-js";

export interface ExifData {
  dateTaken: string | undefined;
  description: string | undefined;
  latitude: string | undefined;
  longitude: string | undefined;
}

export interface IExifManager {
  convertToDate(source?: string): string | undefined;
  load(file: File): Promise<ExifData>;
}

export class ExifManager implements IExifManager {
  convertToDate(source?: string): string | undefined {
    if (!source) {
      return undefined;
    }

    // parse the string
    const match = /^(\d{4}):(\d{2}):(\d{2})/.exec(source);
    if (!match) {
      return undefined;
    }

    const day = Number(match[3]);
    const month = Number(match[2]) - 1;

    // create the date
    const date = new Date();
    date.setFullYear(Number(match[1]), month, day);

    return date.toISOString();
  }

  convertDMSToDD(
    degrees: number,
    minutes: number,
    seconds: number,
    direction: string
  ): number {
    let dd = degrees + minutes / 60 + seconds / 3600;
    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    }
    return dd;
  }

  load(file: File): Promise<ExifData> {
    // get the current object
    const this$ = this;

    return new Promise<ExifData>(
      (resolve: (value: ExifData) => void, reject: () => void) => {
        requestIdleCallback((): void => {
          EXIF.getData(file as any, function (this: any) {
            requestIdleCallback((): void => {
              const exif = EXIF.getAllTags(this);
              let latString: string | undefined;
              let longString: string | undefined;

              const latitude = EXIF.getTag(this, "GPSLatitude") as
                | number[]
                | undefined;
              const latitudeRef = EXIF.getTag(this, "GPSLatitudeRef") as
                | string
                | undefined;
              const longitude = EXIF.getTag(this, "GPSLongitude") as
                | number[]
                | undefined;
              const longitudeRef = EXIF.getTag(this, "GPSLongitudeRef") as
                | string
                | undefined;

              if (latitude && latitudeRef && longitude && longitudeRef) {
                const lat = this$.convertDMSToDD(
                  latitude[0],
                  latitude[1],
                  latitude[2],
                  latitudeRef
                );
                const lon = this$.convertDMSToDD(
                  longitude[0],
                  longitude[1],
                  longitude[2],
                  longitudeRef
                );

                latString = String(lat);
                longString = String(lon);
              }

              resolve({
                dateTaken: this$.convertToDate(exif?.DateTime),
                description: exif?.ImageDescription,
                latitude: latString,
                longitude: longString,
              });
            });
          });
        });
      }
    );
  }
}
