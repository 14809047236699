import "./caption-position-bottom-left.css";

import { useContext, useEffect, useState } from "react";
import { DependencyContext } from "../../DependencyProvider";
import { AppState } from "../../Store";
import { useSelector } from "react-redux";
import { setGalleryItemPosition } from "../../reducers/itemsReducer";
import { useAppDispatch } from "../../Hooks";

const CaptionPositionBottomLeftComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selection
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [activeClassName, setActiveClassName] = useState<string | undefined>(
    undefined
  );
  const [disabled, setDisabled] = useState(selectedItem === undefined);

  const handleClick = (): void => {
    // set the new position
    dispatch(setGalleryItemPosition("BottomLeft"));
  };

  useEffect((): void => {
    setDisabled(selectedItem === undefined);

    // is this the selected button?
    if (
      (selectedItem?.captionPosition ?? settings.defaultCaptionPosition) ===
      "BottomLeft"
    ) {
      setActiveClassName("active");
    } else {
      setActiveClassName(undefined);
    }
  }, [selectedItem, settings.defaultCaptionPosition]);

  return (
    <button
      className={`bottom-left ${activeClassName}`}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      <img alt="Bottom Left" src="images/bottom-left.png" />
    </button>
  );
};

export default CaptionPositionBottomLeftComponent;
