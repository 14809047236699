import "./main.css";

import React from "react";
import WorkspaceComponent from "../workspace/workspace";
import GalleryComponent from "../gallery/gallery";

const MainComponent: React.FC = () => {
  return (
    <main>
      <WorkspaceComponent />
      <GalleryComponent />
    </main>
  );
};

export default MainComponent;
