export interface IColourManager {
  hexToRGBA(hex: string, alpha?: number): string;
}

export class ColourManager {
  hexToRGBA(hex: string, alpha?: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha === undefined) {
      return `rgba(${r}, ${g}, ${b})`;
    } else {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  }
}
