import "./rotate-right.css";

import React, { useState, useEffect } from "react";
import { AppState } from "../../Store";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { rotateGalleryItemRight } from "../../reducers/itemsReducer";

const RotateRightComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selection
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  const [disabled, setDisabled] = useState<boolean>(selectedIndex === -1);

  useEffect(() => {
    setDisabled(selectedIndex === -1);
  }, [selectedIndex]);

  const handleClick = (): void => {
    dispatch(rotateGalleryItemRight());
  };

  return (
    <button
      className="rotate-right"
      disabled={disabled}
      onClick={handleClick}
      title="Rotate Right"
    >
      <img
        alt="Rotate Right"
        className="rotate-right"
        src="images/rotate-right.png"
      />
    </button>
  );
};

export default RotateRightComponent;
