import "./font.css";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { DependencyContext } from "../../DependencyProvider";
import { setGalleryItemFont } from "../../reducers/itemsReducer";
import { FontFamily } from "../../interfaces/application-state";

const FontComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(true);
  const [font, setFont] = useState(settings.defaultFont);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value as FontFamily;

    dispatch(setGalleryItemFont(value));
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setFont(selectedItem?.font ?? settings.defaultFont);
  }, [selectedItem, settings.defaultFont]);

  return (
    <select
      className="font"
      disabled={disabled}
      onChange={handleChange}
      style={{ fontFamily: font }}
      title="Font"
      value={font}
    >
      <option value="cursive" style={{ fontFamily: "cursive" }}>
        Cursive
      </option>
      <option value="fantasy" style={{ fontFamily: "fantasy" }}>
        Fantasy
      </option>
      <option value="monospace" style={{ fontFamily: "monospace" }}>
        Monospace
      </option>
      <option value="sans-serif" style={{ fontFamily: "sans-serif" }}>
        Sans Serif
      </option>
      <option value="serif" style={{ fontFamily: "serif" }}>
        Serif
      </option>
    </select>
  );
};

export default FontComponent;
