import "./opacity.css";

import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DependencyContext } from "../../DependencyProvider";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { setGalleryItemOpacity } from "../../reducers/itemsReducer";

const OpacityComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selector
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(selectedItem === undefined);
  const [value, setValue] = useState(
    selectedItem?.opacity ?? settings.defaultOpacity
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch(setGalleryItemOpacity(Number(event.target.value)));
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setValue(selectedItem?.opacity ?? settings.defaultOpacity);
  }, [selectedItem, settings.defaultOpacity]);

  return (
    <select
      className="opacity"
      disabled={disabled}
      onChange={handleChange}
      title="Background Opacity"
      value={value}
    >
      <option value="0">None</option>
      <option value="25">25%</option>
      <option value="50">50%</option>
      <option value="75">75%</option>
      <option value="100">On</option>
    </select>
  );
};

export default OpacityComponent;
