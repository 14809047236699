import "./captions.scss";

import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useEffect, useState } from "react";
import { GalleryItem } from "../../interfaces/gallery-item";
import { useAppDispatch } from "../../Hooks";
import { setGalleryItemText } from "../../reducers/itemsReducer";

const CaptionsComponent: React.FC = () => {
  const filterByDate = (x1?: GalleryItem, x2?: GalleryItem): boolean => {
    // if either date is wrong, it is not a match
    if (x1?.dateTaken === undefined || x2?.dateTaken === undefined) {
      return false;
    }

    // if it is the same item, it is not a match
    if (x1.text === x2.text) {
      return false;
    }

    return (
      new Date(x1.dateTaken).toDateString() ===
      new Date(x2.dateTaken).toDateString()
    );
  };

  const dispatch = useAppDispatch();

  // selectors
  const items = useSelector((state: AppState) => state.items.items);
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [captions, setCaptions] = useState<string[]>([]);

  const textToHtml = (value: string): string => {
    return value.replace("\n", "<br />");
  };

  useEffect(() => {
    const withDuplicates = items
      .filter((x) => filterByDate(x, selectedItem))
      .map((x) => textToHtml(x.text))
      .sort();

    const withoutDuplicates = [...new Set(withDuplicates)];
    setCaptions(withoutDuplicates);
  }, [items, selectedItem]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    dispatch(setGalleryItemText(e.currentTarget.innerHTML));
  };

  return (
    <div className="captions">
      {captions.map((x, i) => (
        <button
          dangerouslySetInnerHTML={{ __html: x }}
          key={i}
          name={x}
          onClick={handleClick}
          type="button"
        ></button>
      ))}
    </div>
  );
};

export default CaptionsComponent;
