import { CaptionPosition, FontFamily, FontType } from "./application-state";

export enum Rotation {
  None,
  Ninety,
  OneEighty,
  TwoSeventy,
}

export interface Persist {
  backColour?: string;
  canvasHeight?: number;
  canvasWidth?: number;
  captionPosition?: CaptionPosition;
  font?: FontFamily;
  fontSize?: number;
  fontType?: FontType;
  foreColour?: string;
  includeDateTaken?: boolean;
  opacity?: number;
  rotation: Rotation;
  saved: boolean;
  text: string;
  useCanvas?: boolean;
}
