import { GalleryItem } from "../interfaces/gallery-item";
import { Rotation } from "../interfaces/persist";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Settings } from "../services/Settings";
import {
  CaptionPosition,
  FontFamily,
  FontType,
  SortOrder,
} from "../interfaces/application-state";

interface GalleryItemState {
  imageUrl: string | undefined;
  items: GalleryItem[];
  loadedItemCount: number;
  selectedIndex: number;
  readonly selectedItem: GalleryItem | undefined;
  sortOrder: SortOrder;
}

interface SetExifDataPayload {
  id: number;
  dateTaken?: string;
  description?: string;
  latitude: string | undefined;
  longitude: string | undefined;
}

interface SetPreviewUrlPayload {
  id: number;
  previewUrl: string;
}

const initialState: GalleryItemState = {
  imageUrl: undefined,
  items: [],
  loadedItemCount: 0,
  selectedIndex: -1,
  selectedItem: undefined,
  sortOrder: "none",
};

const settings = new Settings();

const setDefaults = (item: GalleryItem): void => {
  item.backColour = item.backColour ?? settings.defaultBackColour;
  item.canvasHeight = item.canvasHeight ?? settings.defaultCanvasHeight;
  item.canvasWidth = item.canvasWidth ?? settings.defaultCanvasWidth;
  item.font = item.font ?? settings.defaultFont;
  item.fontSize = item.fontSize ?? settings.defaultFontSize;
  item.fontType = item.fontType ?? settings.defaultFontType;
  item.foreColour = item.foreColour ?? settings.defaultForeColour;
  item.includeDateTaken =
    item.includeDateTaken ?? settings.defaultIncludeDateTaken;
  item.opacity = item.opacity ?? settings.defaultOpacity;
  item.rotation = item.rotation ?? Rotation.None;
  item.useCanvas = item.useCanvas ?? settings.defaultUseCanvas;

  // the item has been edited
  item.edited = true;

  // the item has not been saved
  item.saved = false;
};

const sort = (items: GalleryItem[], sortOrder: SortOrder): GalleryItem[] => {
  switch (sortOrder) {
    case "dateTaken":
      return items.sort((a: GalleryItem, b: GalleryItem): number => {
        const aDateTaken = a.dateTaken ?? new Date().toISOString();
        const bDateTaken = b.dateTaken ?? new Date().toISOString();

        if (aDateTaken < bDateTaken) {
          return -1;
        } else if (aDateTaken > bDateTaken) {
          return 1;
        } else {
          return 0;
        }
      });
    case "filename":
      return items.sort((a: GalleryItem, b: GalleryItem): number => {
        if (a.fileName < b.fileName) {
          return -1;
        } else if (a.fileName > b.fileName) {
          return 1;
        } else {
          return 0;
        }
      });
    default:
      return items;
  }
};

const htmlToText = (value: string): string => {
  const tempElement = document.createElement("textarea");
  tempElement.innerHTML = value.replace("<br>", "\n");
  return tempElement.textContent || tempElement.innerText;
};

const galleryItemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    addGalleryItems: (state, action: PayloadAction<GalleryItem[]>) => {
      // select the newly added item
      state.selectedIndex = state.items.length;

      // add the new item
      state.items.push(...action.payload);

      // select the new item
      state.selectedItem = state.items[state.selectedIndex];
    },
    clearImageUrl: (state) => {
      // clear the current image url
      state.imageUrl = undefined;
    },
    nextGalleryItem: (state) => {
      // don't do anything if we are already at the end
      if (state.selectedIndex === state.items.length - 1) {
        throw new Error("Attempt to move past last position");
      }

      // set the new selected index
      state.selectedIndex += 1;

      // set the selected item
      state.selectedItem = state.items[state.selectedIndex];
    },
    previousGalleryItem: (state) => {
      // don't do anything if we are already at the start
      if (state.selectedIndex === 0) {
        throw new Error("Attempt to move prior to position 0");
      }

      // set the new selected index
      state.selectedIndex -= 1;

      // set the new selected item
      state.selectedItem = state.items[state.selectedIndex];
    },
    removeAllGalleryItems: (state) => {
      state.items = [];
      state.loadedItemCount = 0;
      state.selectedIndex = -1;
      state.selectedItem = undefined;
    },
    rotateGalleryItemLeft: (state) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to rotate when no item is selected");
      }

      // clone the selected item
      const newSelectedItem = {
        ...state.selectedItem,
      };

      // rotate the image
      switch (newSelectedItem.rotation) {
        case Rotation.None:
          newSelectedItem.rotation = Rotation.TwoSeventy;

          break;
        case Rotation.Ninety:
          newSelectedItem.rotation = Rotation.None;

          break;
        case Rotation.OneEighty:
          newSelectedItem.rotation = Rotation.Ninety;

          break;
        case Rotation.TwoSeventy:
          newSelectedItem.rotation = Rotation.OneEighty;

          break;
      }

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // save it to the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;
    },
    rotateGalleryItemRight: (state) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to rotate when no item is selected");
      }

      // clone the selected item
      const newSelectedItem = {
        ...state.selectedItem,
      };

      // rotate the image
      switch (newSelectedItem.rotation) {
        case Rotation.None:
          newSelectedItem.rotation = Rotation.Ninety;

          break;
        case Rotation.Ninety:
          newSelectedItem.rotation = Rotation.OneEighty;

          break;
        case Rotation.OneEighty:
          newSelectedItem.rotation = Rotation.TwoSeventy;

          break;
        case Rotation.TwoSeventy:
          newSelectedItem.rotation = Rotation.None;

          break;
      }

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // save it to the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;
    },
    selectItem: (state, action: PayloadAction<number>) => {
      // sense check
      if (action.payload > state.items.length - 1) {
        throw new Error("Attempt to select beyond the end of the items");
      }

      // set the selected index
      state.selectedIndex = action.payload;

      // set the selected item
      state.selectedItem = state.items[action.payload];
    },
    setExifData: (state, action: PayloadAction<SetExifDataPayload>) => {
      // get the position of the target item
      const targetPos = state.items.findIndex(
        (x) => x.id === action.payload.id
      );

      // if there was no match, there is no need to do anything
      if (targetPos === -1) {
        return;
      }

      // get the currently selected item
      const selectedItem: GalleryItem = state.items[targetPos];

      // the text only gets updated if the description has been set and the item has not yet been edited
      const text =
        selectedItem.edited || action.payload.description === undefined
          ? selectedItem.text
          : action.payload.description;

      // create a clone of it
      const newItem: GalleryItem = {
        ...selectedItem,
        dateTaken: action.payload.dateTaken,
        isExifLoaded: true,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        text,
      };

      // update the target item
      state.items[targetPos] = newItem;

      // if the target is the selected item, update that too
      if (targetPos === state.selectedIndex) {
        state.selectedItem = newItem;
      }

      // increment the count of loaded items
      state.loadedItemCount++;
    },
    setGalleryItemBackColour: (state, action: PayloadAction<string>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        backColour: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default value
      settings.defaultBackColour = action.payload;
    },
    setGalleryItemCanvasHeight: (state, action: PayloadAction<number>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        canvasHeight: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default value
      settings.defaultCanvasHeight = action.payload;
    },
    setGalleryItemCanvasWidth: (state, action: PayloadAction<number>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        canvasWidth: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default value
      settings.defaultCanvasWidth = action.payload;
    },
    setGalleryItemFont: (state, action: PayloadAction<FontFamily>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        font: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default font
      settings.defaultFont = action.payload;
    },
    setGalleryItemFontSize: (state, action: PayloadAction<number>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        fontSize: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default font
      settings.defaultFontSize = action.payload;
    },
    setGalleryItemFontType: (state, action: PayloadAction<FontType>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        fontType: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default font
      settings.defaultFontType = action.payload;
    },
    setGalleryItemForeColour: (state, action: PayloadAction<string>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        foreColour: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default font
      settings.defaultForeColour = action.payload;
    },
    setGalleryItemIncludeDateTaken: (state, action: PayloadAction<boolean>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        includeDateTaken: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default font
      settings.defaultIncludeDateTaken = action.payload;
    },
    setGalleryItemOpacity: (state, action: PayloadAction<number>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        opacity: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default value
      settings.defaultOpacity = action.payload;
    },
    setGalleryItemPosition: (state, action: PayloadAction<CaptionPosition>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        captionPosition: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default
      settings.defaultCaptionPosition = action.payload;
    },
    setGalleryItemSaved: (state) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        edited: false,
        saved: true,
      };

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;
    },
    setGalleryItemText: (state, action: PayloadAction<string>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        text: htmlToText(action.payload),
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;
    },
    setGalleryItemUseCanvas: (state, action: PayloadAction<boolean>) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // clone the selected item
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        useCanvas: action.payload,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the default
      settings.defaultUseCanvas = action.payload;
    },
    setImageUrl: (state, action: PayloadAction<string>) => {
      // save the url
      state.imageUrl = action.payload;
    },
    setPreviewUrl: (state, action: PayloadAction<SetPreviewUrlPayload>) => {
      // get the position of the target item
      const targetPos = state.items.findIndex(
        (x) => x.id === action.payload.id
      );

      // if there was no match, there is no need to do anything
      if (targetPos === -1) {
        return;
      }

      // create a clone of it
      const newItem = {
        ...state.items[targetPos],
        previewUrl: action.payload.previewUrl,
      };

      // update the target item
      state.items[targetPos] = newItem;

      // if the target is the selected item, update that too
      if (targetPos === state.selectedIndex) {
        state.selectedItem = newItem;
      }
    },
    setSortOrder: (state, action: PayloadAction<SortOrder>) => {
      // set the sort order
      state.sortOrder = action.payload;

      // now sort
      state.items = sort(state.items, action.payload);

      // update the selected item
      state.selectedItem = state.items[state.selectedIndex];
    },
    switchColours: (state) => {
      // don't do anything if no item is selected
      if (state.selectedItem === undefined) {
        throw new Error("Attempt to update when no item is selected");
      }

      // get the current colours
      const backColour =
        state.selectedItem.backColour ?? settings.defaultBackColour;
      const foreColour =
        state.selectedItem.foreColour ?? settings.defaultForeColour;

      // flip the colours
      const newSelectedItem: GalleryItem = {
        ...state.selectedItem,
        backColour: foreColour,
        foreColour: backColour,
      };

      // update the image to persist current defaults
      setDefaults(newSelectedItem);

      // update the state
      state.items[state.selectedIndex] = newSelectedItem;
      state.selectedItem = newSelectedItem;

      // save the new defaults
      settings.defaultBackColour = foreColour;
      settings.defaultForeColour = backColour;
    },
  },
});

export const {
  addGalleryItems,
  clearImageUrl,
  nextGalleryItem,
  previousGalleryItem,
  removeAllGalleryItems,
  rotateGalleryItemLeft,
  rotateGalleryItemRight,
  selectItem,
  setExifData,
  setGalleryItemBackColour,
  setGalleryItemCanvasHeight,
  setGalleryItemCanvasWidth,
  setGalleryItemFont,
  setGalleryItemFontSize,
  setGalleryItemFontType,
  setGalleryItemForeColour,
  setGalleryItemIncludeDateTaken,
  setGalleryItemOpacity,
  setGalleryItemPosition,
  setGalleryItemSaved,
  setGalleryItemText,
  setGalleryItemUseCanvas,
  setImageUrl,
  setPreviewUrl,
  setSortOrder,
  switchColours,
} = galleryItemSlice.actions;
export default galleryItemSlice.reducer;
