import { useSelector } from "react-redux";
import "./sort.scss";
import { AppState } from "../../Store";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../Hooks";
import { setSortOrder } from "../../reducers/itemsReducer";
import { SortOrder } from "../../interfaces/application-state";

const SortComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  // selectors
  const itemCount = useSelector((state: AppState) => state.items.items.length);
  const loadedItemCount = useSelector(
    (state: AppState) => state.items.loadedItemCount
  );
  const sortOrder = useSelector((state: AppState) => state.items.sortOrder);

  // state
  const [isExifLoaded, setIsExifLoaded] = useState<boolean>(
    loadedItemCount === itemCount
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSortOrder(event.target.value as SortOrder));
  };

  useEffect(() => {
    setIsExifLoaded(loadedItemCount === itemCount);
  }, [itemCount, loadedItemCount]);

  return (
    <div className="sort">
      Sort
      <select onChange={handleChange} value={sortOrder}>
        <option></option>
        <option value="filename">Filename</option>
        {isExifLoaded && <option value="dateTaken">Date Taken</option>}
      </select>
      {!isExifLoaded && <img alt="Loading..." src="/images/loading-sort.gif" />}
    </div>
  );
};

export default SortComponent;
