import "./caption.css";

import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { AppState } from "../../Store";
import { useDispatch } from "react-redux";
import { setGalleryItemText } from "../../reducers/itemsReducer";

const CaptionComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );
  const text = useSelector((state: AppState) => state.items.selectedItem?.text);

  // state
  const [disabled, setDisabled] = useState(true);
  const [localText, setLocalText] = useState(text);

  // refs
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    // update the local text
    setLocalText(event.target.value);

    // cancel any pending update
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      // update the prop value
      dispatch(setGalleryItemText(event.target.value));
    }, 400);
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setLocalText(text ?? "");
  }, [selectedItem, text]);

  return (
    <textarea
      className="caption"
      disabled={disabled}
      value={localText}
      onChange={handleChange}
    ></textarea>
  );
};

export default CaptionComponent;
