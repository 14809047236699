import "./canvas-dimensions.css";

import CanvasWidthComponent from "../canvas-width/canvas-width";
import UseCanvasComponent from "../use-canvas/use-canvas";

import CanvasHeightComponent from "../canvas-height/canvas-height";

const CanvasDimensionsComponent: React.FC = () => {
  return (
    <div className="canvas-dimensions">
      <UseCanvasComponent />
      <CanvasWidthComponent />
      <CanvasHeightComponent />
    </div>
  );
};

export default CanvasDimensionsComponent;
