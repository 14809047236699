import { useSelector } from "react-redux";
import { AppState } from "../../Store";

const PositionComponent: React.FC = () => {
  // selectors
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  return <>{selectedIndex + 1}</>;
};

export default PositionComponent;
