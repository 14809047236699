import React, { StrictMode } from "react";

import DependenciesProvider from "./DependencyProvider";
import PhotoLabelComponent from "./components/photo-label/photo-label";

import store from "./Store";

import "./App.css";
import { Provider } from "react-redux";

const AppComponent: React.FC = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <DependenciesProvider>
          <PhotoLabelComponent></PhotoLabelComponent>
        </DependenciesProvider>
      </Provider>
    </StrictMode>
  );
};

export default AppComponent;
