interface AsyncQueueItem {
  id: number;
  task: () => Promise<void>;
}

export interface IAsyncQueue {
  clear(): void;
  enqueue(id: number, task: () => Promise<void>): void;
  prioritise(id: number): boolean;
}

export class AsyncQueue implements IAsyncQueue {
  private queue: Array<AsyncQueueItem> = [];

  // clear all items from the queue
  clear(): void {
    this.queue.length = 0;
  }

  // Add item to the queue
  enqueue(id: number, task: () => Promise<void>): void {
    // add this item to the queue
    this.queue.push({
      id,
      task,
    });

    // if there is only one item, start the queue
    if (this.queue.length === 1) {
      this.processQueue();
    }
  }

  prioritise(id: number): boolean {
    // is there a task queued for that id?
    const index = this.queue.findIndex((i) => i.id === id);

    // does it exist?
    if (index < 1) {
      return false;
    }

    // move it to the top of the queue
    const items = this.queue.splice(index, 1);
    this.queue.splice(1, 0, items[0]);

    return true;
  }

  // Process the queue asynchronously
  private async processQueue() {
    while (this.queue.length > 0) {
      const item = this.queue[0];
      try {
        // run the next queued item
        await item.task();
      } catch (err) {
      } finally {
        // remove this item from the array
        this.queue.shift();
      }
    }
  }
}
