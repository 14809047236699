import "./location.scss";

import { AppState } from "../../Store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const LocationComponent: React.FC = () => {
  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(
    selectedItem?.latitude === undefined
  );

  // event handlers
  const handleClick = () => {
    window.open(
      `https://www.google.com/maps/place/${selectedItem?.latitude},${selectedItem?.longitude}`
    );
  };

  useEffect(() => {
    setDisabled(selectedItem?.latitude === undefined);
  }, [selectedItem?.latitude]);

  return (
    <button className="location" disabled={disabled} onClick={handleClick}>
      <img alt="Where is this?" src="images/location.png" />
    </button>
  );
};

export default LocationComponent;
