import "./canvas.css";

import React, { useRef, useEffect, useContext } from "react";

import { Rotation } from "../../interfaces/persist";
import { DependencyContext } from "../../DependencyProvider";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useAppDispatch } from "../../Hooks";
import { clearImageUrl, setImageUrl } from "../../reducers/itemsReducer";

const CanvasComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  // context
  const fileManager = useContext(DependencyContext)!.fileManager;
  const imageRenderer = useContext(DependencyContext)!.imageRenderer;
  const settings = useContext(DependencyContext)!.settings;

  // refs
  const abortControllerRef = useRef<AbortController>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    // create a new instance of the controller
    abortControllerRef.current = new AbortController();

    // clear the current image url
    dispatch(clearImageUrl());

    // is there any selected item?
    if (!selectedItem) {
      imageRenderer.clear(canvasRef.current, abortControllerRef.current);

      return;
    }

    // get the file to be rendered
    const file = fileManager.get(selectedItem.id)!;

    imageRenderer
      .render(
        file,
        canvasRef.current,
        selectedItem.backColour ?? settings.defaultBackColour,
        selectedItem.canvasHeight ?? settings.defaultCanvasHeight,
        selectedItem.canvasWidth ?? settings.defaultCanvasWidth,
        selectedItem.captionPosition ?? settings.defaultCaptionPosition,
        selectedItem.dateTaken,
        selectedItem.font ?? settings.defaultFont,
        selectedItem.fontSize ?? settings.defaultFontSize,
        selectedItem.fontType ?? settings.defaultFontType,
        selectedItem.foreColour ?? settings.defaultForeColour,
        selectedItem.includeDateTaken ?? settings.defaultIncludeDateTaken,
        selectedItem.opacity ?? settings.defaultOpacity,
        selectedItem.rotation ?? Rotation.None,
        selectedItem.text ?? "",
        selectedItem.useCanvas ?? settings.defaultUseCanvas,
        abortControllerRef.current
      )
      .then((value: string) => {
        dispatch(setImageUrl(value));
      })
      .catch((reason: any) => {
        console.error(reason);
      });

    return () => {
      abortControllerRef.current!.abort();
    };
  }, [
    dispatch,
    fileManager,
    imageRenderer,
    selectedItem,
    settings.defaultBackColour,
    settings.defaultCanvasHeight,
    settings.defaultCanvasWidth,
    settings.defaultCaptionPosition,
    settings.defaultFont,
    settings.defaultFontSize,
    settings.defaultFontType,
    settings.defaultForeColour,
    settings.defaultIncludeDateTaken,
    settings.defaultOpacity,
    settings.defaultUseCanvas,
  ]);

  return (
    <div className="canvas" data-testid="canvas">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CanvasComponent;
