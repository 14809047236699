import "./forecolour.css";

import React, { useContext, useEffect, useState } from "react";

import { DependencyContext } from "../../DependencyProvider";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useDispatch } from "react-redux";
import { setGalleryItemForeColour } from "../../reducers/itemsReducer";

const ForeColourComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selector
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState(settings.defaultForeColour);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGalleryItemForeColour(event.target.value));
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setValue(selectedItem?.foreColour ?? settings.defaultForeColour);
  }, [selectedItem, settings.defaultForeColour]);

  return (
    <input
      disabled={disabled}
      onChange={handleChange}
      className="forecolour"
      title="Text Colour"
      type="color"
      value={value ?? settings.defaultForeColour}
    />
  );
};

export default ForeColourComponent;
