import "./workspace.css";

import React from "react";
import CaptionComponent from "../caption/caption";
import CanvasComponent from "../canvas/canvas";
import IncludeDateComponent from "../include-date/include-date";
import CanvasDimensionsComponent from "../canvas-dimensions/canvas-dimensions";
import CaptionPositionComponent from "../caption-position/caption-position";
import CaptionsComponent from "../captions/captions";

const WorkspaceComponent: React.FC = () => {
  return (
    <div className="workspace">
      <div className="container">
        <CanvasComponent />
        <CaptionsComponent />
        <div className="row">
          <div className="caption">
            <CaptionComponent />
            <IncludeDateComponent />
          </div>
          <CaptionPositionComponent />
          <CanvasDimensionsComponent />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceComponent;
