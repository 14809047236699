import "./toolbar.css";

import React from "react";
import OpenComponent from "../open/open";
import CloseComponent from "../close/close";
import NextComponent from "../next/next";
import PreviousComponent from "../previous/previous";
import RotateRightComponent from "../rotate-right/rotate-right";
import RotateLeftComponent from "../rotate-left/rotate-left";
import FontComponent from "../font/font";
import FontSizeComponent from "../font-size/font-size";
import FontTypeComponent from "../font-type/font-type";
import ForeColourComponent from "../forecolour/forecolour";
import BackColourComponent from "../backcolour/backcolour";
import SwitchColoursComponent from "../switch-colours/switch-colours";
import OpacityComponent from "../opacity/opacity";
import SaveComponent from "../save/save";
import LocationComponent from "../location/location";

interface ToolbarComponentProps {
  onFileSelect: (files: FileList) => void;
}

const ToolbarComponent: React.FC<ToolbarComponentProps> = ({
  onFileSelect,
}) => {
  const handleFileSelect = (files: FileList): void => {
    onFileSelect(files);
  };

  return (
    <nav className="toolbar">
      <OpenComponent onFileSelect={handleFileSelect} />
      <CloseComponent />
      <SaveComponent />
      <div className="spacer"></div>
      <PreviousComponent />
      <NextComponent />
      <div className="spacer"></div>
      <RotateLeftComponent />
      <RotateRightComponent />
      <div className="spacer"></div>
      <FontComponent />
      <FontSizeComponent />
      <FontTypeComponent />
      <ForeColourComponent />
      <SwitchColoursComponent />
      <BackColourComponent />
      <OpacityComponent />
      <div className="spacer"></div>
      <LocationComponent />
    </nav>
  );
};

export default ToolbarComponent;
