import "./caption-position.css";

import CaptionPositionBottomRightComponent from "../caption-position-bottom-right/caption-position-bottom-right";
import CaptionPositionBottomMiddleComponent from "../caption-position-bottom-middle/caption-position-bottom-middle";
import CaptionPositionBottomLeftComponent from "../caption-position-bottom-left/caption-position-bottom-left";
import CaptionPositionTopLeftComponent from "../caption-position-top-left/caption-position-top-left";
import CaptionPositionTopMiddleComponent from "../caption-position-top-middle/caption-position-top-middle";
import CaptionPositionTopRightComponent from "../caption-position-top-right/caption-position-top-right";

const CaptionPositionComponent: React.FC = () => {
  return (
    <div className="caption-position">
      <div>
        <CaptionPositionTopLeftComponent />
        <CaptionPositionTopMiddleComponent />
        <CaptionPositionTopRightComponent />
      </div>
      <div>
        <CaptionPositionBottomLeftComponent />
        <CaptionPositionBottomMiddleComponent />
        <CaptionPositionBottomRightComponent />
      </div>
    </div>
  );
};

export default CaptionPositionComponent;
