import React, { ChangeEvent, useContext, useRef, useState } from "react";
import "./open.scss";
import { DependencyContext } from "../../DependencyProvider";

interface OpenComponentProps {
  onFileSelect: (files: FileList) => void;
}

const OpenComponent: React.FC<OpenComponentProps> = ({ onFileSelect }) => {
  // context
  const settings = useContext(DependencyContext)!.settings;

  const fileInputRef = useRef<HTMLInputElement>(null);

  // state
  const [showAssistant, setShowAssistant] = useState(settings.showAssistant);

  const setFiles = (event: ChangeEvent<HTMLInputElement>) => {
    // only callback if files have been selected
    if (event.target.files) {
      // callback
      onFileSelect(event.target.files);

      // clear the selected files
      event.target.value = "";

      // hide the assistant
      settings.showAssistant = false;
      setShowAssistant(false);
    }
  };

  const showFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="open">
      <button
        className="open"
        data-testid="button"
        type="button"
        onClick={showFileDialog}
        title="Open"
      >
        <img alt="Select Photos..." className="open" src="images/open.png" />
      </button>
      <input
        className="open"
        data-testid="file"
        onChange={setFiles}
        ref={fileInputRef}
        type="file"
        accept="image/*"
        multiple
      />
      {showAssistant && (
        <div className="callout">
          <div className="arrow">
            <img alt="" src="images/callout.png" />
          </div>
          <div className="assistant">Click here to select photos</div>
        </div>
      )}
    </div>
  );
};

export default OpenComponent;
