export interface IFileManager {
  add(id: number, file: File): void;
  clear(): void;
  get(id: number): File | undefined;
  getFileName(id: number | undefined): string;
}

export class FileManager implements IFileManager {
  private files: any = {};

  add(id: number, file: File): void {
    // create the key
    const key = this.buildKey(id);

    this.files[key] = file;
  }

  private buildKey(id: number): string {
    return String(id);
  }

  clear(): void {
    this.files = {};
  }

  get(id: number) {
    const key = this.buildKey(id);

    return this.files[key];
  }

  getFileName(id: number | undefined): string {
    // has a file been specified?
    if (id === undefined) {
      return "";
    }

    const key = this.buildKey(id);

    // get the file with that key
    const file = this.files[key];

    // if it doesn't exist, return an empty string
    if (file === undefined) {
      return "";
    }

    const match = file.name.match(/^(.+)\.(.+?)$/i);

    if (match) {
      return match[1];
    }

    return file.name;
  }
}
