import { GalleryItem } from "../interfaces/gallery-item";
import { Persist } from "../interfaces/persist";
import { IFileManager } from "./FileManager";

export interface IPersistManager {
  get(file: File): Persist | undefined;
  save(item: GalleryItem): boolean;
}

export class PersistManager implements IPersistManager {
  constructor(private readonly fileManager: IFileManager) {}

  private buildKey(
    filename: string,
    fileSize: number,
    dateLastModified: number
  ): string {
    return `${filename}:${fileSize}:${dateLastModified}`;
  }

  private buildPersist(source: any): Persist {
    const persist: Persist = {
      backColour: source.backColour,
      canvasHeight: source.canvasHeight,
      canvasWidth: source.canvasWidth,
      captionPosition: source.captionPosition,
      font: source.font,
      fontSize: source.fontSize,
      fontType: source.fontType,
      foreColour: source.foreColour,
      includeDateTaken: source.includeDateTaken,
      opacity: source.opacity,
      rotation: source.rotation,
      saved: true,
      text: source.text,
      useCanvas: source.useCanvas,
    };

    return persist;
  }

  get(file: File): Persist | undefined {
    // get the key
    const key = this.buildKey(file.name, file.size, file.lastModified);

    // try and load the item from the storage
    const result = localStorage.getItem(key);

    // was it saved?
    if (result === null) {
      return undefined;
    }

    // split the value into it's components
    const localStorageArray = result.split(":");

    // was the value valid?
    if (!localStorageArray || localStorageArray.length !== 2) {
      return undefined;
    }

    // get the json
    const json = atob(localStorageArray[0]);

    // create the object
    const item = JSON.parse(json);

    // update the last accessed date for this item
    localStorage.setItem(
      key,
      `${localStorageArray[0]}:${new Date().getDate()}`
    );

    // remove any items that are not on the Persist interface
    return this.buildPersist(item);
  }

  save(item: GalleryItem): boolean {
    // get the file associated with this item
    const file = this.fileManager.get(item.id);

    // if the file could not be found, don't persist this file
    if (file === undefined) {
      return false;
    }

    // load the image from the file
    try {
      // build the key
      const key = this.buildKey(file.name, file.size, file.lastModified);

      // remove the items we don't want to persist
      const persist = this.buildPersist(item);

      // encrypt it as base64
      const json = JSON.stringify(persist);
      const base64 = btoa(json);

      // now save it
      localStorage.setItem(key, `${base64}:${new Date().getDate()}`);

      return true;
    } catch {
      return false;
    }
  }
}
