import "./canvas-width.css";

import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { setGalleryItemCanvasWidth } from "../../reducers/itemsReducer";
import { DependencyContext } from "../../DependencyProvider";
import { useAppDispatch } from "../../Hooks";

const CanvasWidthComponent: FC = () => {
  const dispatch = useAppDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );
  const width = useSelector(
    (state: AppState) => state.items.selectedItem?.canvasWidth
  );

  // state
  const [disabled, setDisabled] = useState(
    selectedItem === undefined || !selectedItem.useCanvas
  );
  const [localWidth, setLocalWidth] = useState<number>(
    width ?? settings.defaultCanvasWidth
  );

  // refs
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.target.value);

    // set the value to use in the control
    setLocalWidth(value);

    // cancel any pending update
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      dispatch(setGalleryItemCanvasWidth(value));
    });
  };

  useEffect(() => {
    const useCanvas = selectedItem?.useCanvas ?? settings.defaultUseCanvas;

    setDisabled(selectedItem === undefined || !useCanvas);
    setLocalWidth(selectedItem?.canvasWidth ?? settings.defaultCanvasWidth);
  }, [selectedItem, settings.defaultCanvasWidth, settings.defaultUseCanvas]);

  return (
    <div className="canvas-width">
      W
      <input
        disabled={disabled}
        min="1"
        onChange={handleChange}
        value={localWidth}
        type="number"
      />
    </div>
  );
};

export default CanvasWidthComponent;
