export interface IDateManager {
  getDate(date: Date): string;
}

export class DateManager implements IDateManager {
  getDate(date: Date): string {
    // get the date as a string
    const dateAsString = date.toLocaleDateString(undefined, {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    });

    // remove any leading zeros (to make the date format shorter)
    const shortenedDateAsString = dateAsString.replace(/0(\d)\//g, "$1/");

    return shortenedDateAsString;
  }
}
