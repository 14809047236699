import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";

const LengthComponent: React.FC = () => {
  // selectors
  const items = useSelector((state: AppState) => state.items.items);

  return <>{items.length} photos</>;
};

export default LengthComponent;
