import {
  CaptionPosition,
  FontFamily,
  FontType,
} from "../interfaces/application-state";

export interface ISettings {
  defaultBackColour: string;
  defaultCanvasHeight: number;
  defaultCanvasWidth: number;
  defaultCaptionPosition: CaptionPosition;
  defaultFont: FontFamily;
  defaultFontSize: number;
  defaultFontType: FontType;
  defaultForeColour: string;
  defaultIncludeDateTaken: boolean;
  defaultOpacity: number;
  defaultUseCanvas: boolean;
  showAssistant: boolean;
}

export class Settings implements ISettings {
  get defaultBackColour(): string {
    return localStorage.getItem("defaultBackColour") ?? "#000000";
  }

  set defaultBackColour(value: string) {
    localStorage.setItem("defaultBackColour", value);
  }

  get defaultCanvasHeight(): number {
    return Number(localStorage.getItem("defaultCanvasHeight") ?? 480);
  }

  set defaultCanvasHeight(value: number) {
    localStorage.setItem("defaultCanvasHeight", String(value));
  }

  get defaultCanvasWidth(): number {
    return Number(localStorage.getItem("defaultCanvasWidth") ?? 640);
  }

  set defaultCanvasWidth(value: number) {
    localStorage.setItem("defaultCanvasWidth", String(value));
  }

  get defaultCaptionPosition(): CaptionPosition {
    return (
      (localStorage.getItem("defaultCaptionPosition") as CaptionPosition) ??
      "TopLeft"
    );
  }

  set defaultCaptionPosition(value: CaptionPosition) {
    localStorage.setItem("defaultCaptionPosition", value);
  }

  get defaultFont(): FontFamily {
    return (localStorage.getItem("defaultFont") as FontFamily) ?? "cursive";
  }

  set defaultFont(value: FontFamily) {
    localStorage.setItem("defaultFont", value);
  }

  get defaultFontSize(): number {
    return Number(localStorage.getItem("defaultFontSize") ?? 10);
  }

  set defaultFontSize(value: number) {
    localStorage.setItem("defaultFontSize", String(value));
  }

  get defaultFontType(): FontType {
    return (localStorage.getItem("defaultFontType") as FontType) ?? "%";
  }

  set defaultFontType(value: string) {
    localStorage.setItem("defaultFontType", value);
  }

  get defaultForeColour(): string {
    return localStorage.getItem("defaultForeColour") ?? "#000000";
  }

  set defaultForeColour(value: string) {
    localStorage.setItem("defaultForeColour", value);
  }

  get defaultIncludeDateTaken(): boolean {
    return /^true$/i.test(
      localStorage.getItem("defaultIncludeDateTaken") ?? ""
    );
  }

  set defaultIncludeDateTaken(value: boolean) {
    localStorage.setItem("defaultIncludeDateTaken", String(value));
  }

  get defaultOpacity(): number {
    return Number(localStorage.getItem("defaultOpacity") ?? 0);
  }

  set defaultOpacity(value: number) {
    localStorage.setItem("defaultOpacity", String(value));
  }

  get defaultUseCanvas(): boolean {
    return /^true$/i.test(localStorage.getItem("defaultUseCanvas") ?? "");
  }

  set defaultUseCanvas(value: boolean) {
    localStorage.setItem("defaultUseCanvas", String(value));
  }

  get showAssistant(): boolean {
    return (localStorage.getItem("showAssistant") ?? "true") === "true";
  }

  set showAssistant(value: boolean) {
    localStorage.setItem("showAssistant", value ? "true" : "false");
  }
}
