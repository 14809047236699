import "./include-date.scss";

import { useContext, useEffect, useState } from "react";

import { DependencyContext } from "../../DependencyProvider";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { setGalleryItemIncludeDateTaken } from "../../reducers/itemsReducer";
import { useAppDispatch } from "../../Hooks";

const IncludeDateComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  // context
  const dateManager = useContext(DependencyContext)!.dateManager;
  const settings = useContext(DependencyContext)!.settings;

  // selection
  const dateTaken = useSelector(
    (state: AppState) => state.items.selectedItem?.dateTaken
  );
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [label, setLabel] = useState<string | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setGalleryItemIncludeDateTaken(event.target.checked));
  };

  useEffect((): void => {
    // if there is no date taken, we don't need to do anything
    if (dateTaken === undefined) {
      return;
    }

    // convert to the correct date format
    const dateTakenAsDate = new Date(dateTaken);
    const date = dateManager.getDate(dateTakenAsDate);

    setLabel(date);
  }, [dateManager, dateTaken]);

  return (
    <>
      {dateTaken && (
        <label className="include-date">
          <input
            checked={
              selectedItem?.includeDateTaken ?? settings.defaultIncludeDateTaken
            }
            onChange={handleChange}
            type="checkbox"
          ></input>
          Append&nbsp;{label}?
        </label>
      )}
    </>
  );
};

export default IncludeDateComponent;
