import React from "react";
import LengthComponent from "../length/length";
import PositionComponent from "../position/position";

import "./footer.css";
import ExifLoadedComponent from "../exif-loaded/exif-loaded";

const FooterComponent: React.FC = () => {
  return (
    <nav className="footer">
      <span>
        <PositionComponent />
      </span>
      <ExifLoadedComponent />
      &nbsp;/&nbsp;
      <LengthComponent />
    </nav>
  );
};

export default FooterComponent;
