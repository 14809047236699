import "./gallery-item.scss";

import React, { useContext, useEffect, useState } from "react";
import { GalleryItem } from "../../interfaces/gallery-item";
import { DependencyContext } from "../../DependencyProvider";

interface GalleryItemProps {
  item: GalleryItem;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
}

const GalleryItemComponent: React.FC<GalleryItemProps> = ({
  item,
  onClick,
  selected,
}) => {
  // context
  const fileManager = useContext(DependencyContext)!.fileManager;

  // state
  const [file, setFile] = useState(fileManager.get(item.id));

  // event handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onClick(event);
  };

  useEffect(() => {
    setFile(fileManager.get(item.id));
  }, [fileManager, item.id]);

  return (
    <li className={`gallery-item ${selected ? "selected" : ""}`}>
      {item.edited && (
        <img alt="Edited" className="status" src="images/edited.png" />
      )}
      {item.saved && (
        <img alt="Saved" className="status" src="images/saved.png" />
      )}
      <button disabled={selected} onClick={handleClick} type="button">
        <img alt={file?.name} src={item.previewUrl} />
      </button>
    </li>
  );
};

export default GalleryItemComponent;
