import { useSelector } from "react-redux";
import { AppState } from "../../Store";

const ExifLoadedComponent: React.FC = () => {
  // selectors
  const loadedItemCount = useSelector(
    (state: AppState) => state.items.loadedItemCount
  );

  return <>{loadedItemCount}</>;
};

export default ExifLoadedComponent;
