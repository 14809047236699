import "./save.css";

import { useSelector, useStore } from "react-redux";
import { AppState } from "../../Store";
import { useContext, useEffect, useRef, useState } from "react";
import { DependencyContext } from "../../DependencyProvider";
import { useAppDispatch } from "../../Hooks";
import { setGalleryItemSaved } from "../../reducers/itemsReducer";

const SaveComponent: React.FC = () => {
  const appDispatch = useAppDispatch();
  const store = useStore<AppState>();

  // context
  const fileManager = useContext(DependencyContext)!.fileManager;
  const persistManager = useContext(DependencyContext)!.persistManager;

  // refs
  const anchorRef = useRef<HTMLAnchorElement>(null);

  // selectors
  const imageUrl = useSelector((state: AppState) => state.items.imageUrl);
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(imageUrl === undefined);
  const [filename, setFilename] = useState(
    `${fileManager.getFileName(selectedItem?.id)}.jpg`
  );

  const handleClick = () => {
    // update the item to reflect that it has been saved
    appDispatch(setGalleryItemSaved());

    // get the updated state
    const updatedState = store.getState();

    // persist the item to local storage
    persistManager.save(updatedState.items.selectedItem!);

    // now run the download to let the user save it
    anchorRef.current?.click();
  };

  useEffect(() => {
    setDisabled(imageUrl === undefined);

    // get the filename minus the extension
    const fileName = fileManager.getFileName(selectedItem?.id);
    setFilename(`${fileName}.jpg`);
  }, [fileManager, imageUrl, selectedItem]);

  return (
    <>
      <a className="save" download={filename} href={imageUrl} ref={anchorRef}>
        &nbsp;
      </a>
      <button className="save" disabled={disabled} onClick={handleClick}>
        <img alt="Save" src="images/save.png" />
      </button>
    </>
  );
};

export default SaveComponent;
