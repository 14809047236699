import "./gallery.scss";

import React, { useContext, useEffect, useRef } from "react";
import GalleryItemComponent from "../gallery-item/gallery-item";
import { DependencyContext } from "../../DependencyProvider";

import { useSelector } from "react-redux";
import { AppState } from "../../Store";

import { GalleryItem } from "../../interfaces/gallery-item";
import { useDispatch } from "react-redux";
import { selectItem } from "../../reducers/itemsReducer";
import SortComponent from "../sort/sort";

const GalleryComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const loadQueue = useContext(DependencyContext)!.loadAsyncQueue;
  const exifQueue = useContext(DependencyContext)!.exifAsyncQueue;

  // selectors
  const items = useSelector((state: AppState) => state.items.items);
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  // ref
  const galleryRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const scrollIntoView = () => {
      const ul = galleryRef.current;

      if (!ul) return;

      const target = ul.children[selectedIndex] as HTMLLIElement;
      const ulRect = ul.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();

      if (targetRect.top < ulRect.top) {
        ul.scrollTo({
          top: target.offsetTop - ul.offsetTop,
        });
      } else if (targetRect.bottom > ulRect.bottom) {
        ul.scrollTo({
          top:
            target.offsetTop +
            target.offsetHeight -
            (ul.offsetTop + ul.offsetHeight),
        });
      }
    };

    if (!galleryRef.current || selectedIndex === -1) {
      return;
    }

    scrollIntoView();
  }, [selectedIndex]);

  const handleClick = (index: number) => () => {
    dispatch(selectItem(index));
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.currentTarget;
    const parentRect = target.getBoundingClientRect();

    for (let i = items.length - 1; i >= 0; i--) {
      const child = target.children.item(i) as HTMLElement;
      const childRect = child.getBoundingClientRect();

      if (!childRect) continue;

      if (
        (childRect.bottom >= parentRect.top &&
          childRect.bottom <= parentRect.bottom) ||
        (childRect.top >= parentRect.top && childRect.top <= parentRect.bottom)
      ) {
        exifQueue.prioritise(items[i].id);
        loadQueue.prioritise(items[i].id);
      }
    }
  };

  return (
    <div className="gallery">
      <SortComponent />
      <ul onScroll={handleScroll} ref={galleryRef}>
        {items.map((item: GalleryItem, index: number) => (
          <GalleryItemComponent
            item={item}
            onClick={handleClick(index)}
            selected={index === selectedIndex}
            key={item.id}
          />
        ))}
      </ul>
    </div>
  );
};

export default GalleryComponent;
