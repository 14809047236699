import "./switch-colours.css";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { switchColours } from "../../reducers/itemsReducer";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";

const SwitchColoursComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selector
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(true);

  const handleClick = (): void => {
    dispatch(switchColours());
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
  }, [selectedItem]);

  return (
    <button
      className="switch-colours"
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      &lt;=&gt;
    </button>
  );
};

export default SwitchColoursComponent;
