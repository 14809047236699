import { useDispatch } from "react-redux";
import { AppDispatch, AppState } from "../../Store";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { DependencyContext } from "../../DependencyProvider";
import { setGalleryItemUseCanvas } from "../../reducers/itemsReducer";

const UseCanvasComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // context
  const settings = useContext(DependencyContext)!.settings!;

  // selector
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [checked, setChecked] = useState(settings.defaultUseCanvas);
  const [disabled, setDisabled] = useState(selectedItem === undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGalleryItemUseCanvas(event.target.checked));
  };

  useEffect(() => {
    setChecked(selectedItem?.useCanvas ?? settings.defaultUseCanvas);
    setDisabled(selectedItem === undefined);
  }, [selectedItem, settings.defaultUseCanvas]);

  return (
    <label>
      <input
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        type="checkbox"
      />{" "}
      Use a canvas?
    </label>
  );
};

export default UseCanvasComponent;
