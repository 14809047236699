import { ICache } from "./Cache";
import Tiff from "tiff.js";

export interface IImageReader {
  read(file: File, cache: boolean): Promise<HTMLImageElement>;
}

export class ImageReader implements IImageReader {
  private cache: ICache<File, HTMLImageElement>;

  constructor(cache: ICache<File, HTMLImageElement>) {
    this.cache = cache;
  }

  read(file: File, cache: boolean): Promise<HTMLImageElement> {
    if (!(file instanceof File)) {
      throw new Error("Invalid file parameter.");
    }

    return new Promise((resolve, reject) => {
      const cachedValue = this.cache.get(file);
      if (cachedValue) {
        resolve(cachedValue);

        return;
      }

      requestIdleCallback(async () => {
        try {
          const imageDataUrl = await this.readFile(file);
          const image = await this.loadImage(imageDataUrl);

          // do we cache it after loading it?
          if (cache) {
            this.cache.push(file, image);
          }

          resolve(image);
        } catch (error) {
          reject(new Error("Failed to read or process the file."));
        }
      });
    });
  }

  private readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.readAsDataURL(file);

      // Timeout handling
      setTimeout(() => {
        if (!fileReader.result) {
          reject(new Error("File reading timeout."));
        }
      }, 5000); // 5 seconds timeout
    });
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise(async (resolve, reject) => {
      const image = new Image();

      image.onerror = () => {
        reject(new Error("Failed to load the image."));
      };

      image.onload = () => {
        resolve(image);
      };

      // special handling for Tiff images
      if (src.match(/^data:image\/tif[f];base64,/i)) {
        // convert the data url to a Uint8Array
        const base64Data = src.split(",")[1];
        const binaryString = atob(base64Data);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        // now process it as a Tiff image
        const tiffBuffer = new Uint8Array(uint8Array);
        const tiffImage = new Tiff({
          buffer: tiffBuffer,
        });
        image.src = tiffImage.toDataURL();
      } else {
        image.src = src;
      }
    });
  }
}
