import "./font-size.css";

import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { AppState } from "../../Store";
import { DependencyContext } from "../../DependencyProvider";
import { useDispatch } from "react-redux";
import { setGalleryItemFontSize } from "../../reducers/itemsReducer";

const FontSizeComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const settings = useContext(DependencyContext)!.settings;

  // selectors
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(true);
  const [fontSize, setFontSize] = useState(settings.defaultFontSize);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setGalleryItemFontSize(Number(event.target.value)));
  };

  useEffect(() => {
    setDisabled(selectedItem === undefined);
    setFontSize(selectedItem?.fontSize ?? settings.defaultFontSize);
  }, [selectedItem, settings.defaultFontSize]);

  return (
    <input
      className="font-size"
      disabled={disabled}
      onChange={handleChange}
      title="Font Size"
      type="number"
      value={fontSize}
    />
  );
};

export default FontSizeComponent;
