import "./rotate-left.css";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useDispatch } from "react-redux";
import { rotateGalleryItemLeft } from "../../reducers/itemsReducer";

const RotateLeftComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  // state
  const [disabled, setDisabled] = useState<boolean>(selectedIndex === -1);

  useEffect(() => {
    setDisabled(selectedIndex === -1);
  }, [selectedIndex]);

  const handleClick = (): void => {
    dispatch(rotateGalleryItemLeft());
  };

  return (
    <button
      className="rotate-left"
      disabled={disabled}
      onClick={handleClick}
      title="Rotate Left"
    >
      <img
        alt="Rotate Left"
        className="rotate-left"
        src="images/rotate-left.png"
      />
    </button>
  );
};

export default RotateLeftComponent;
